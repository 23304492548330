import Statusbar from "../../components/Statusbar";
import Searchbar from "../../components/Searchbar";
import DataTable from "../../components/DataTable";
import useFetchProductos from "../../hooks/useFetchProductos";
import "./styles.css";

const Home = () => {
  useFetchProductos();

  return (
    <main>
      <Statusbar />
      <Searchbar />
      <DataTable />
    </main>
  );
};

export default Home;
