const routes = Object.freeze({
  HOME: {
    key: "home",
    label: "Inicio",
    path: "/",
  },
  NOT_FOUND: {
    key: "not-found",
    path: "*",
  },
});

export default routes;
