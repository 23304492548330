import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import supabase from "../helpers/supabase";
import { setUser } from "../redux/auth/actions";
import { userSelector } from "../redux/auth/selectors";
import { openIndexedDB } from "../indexedDB";

const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      openIndexedDB().finally(() => {
        setLoading(false);
      });
    } else {
      supabase.auth
        .signInAnonymously()
        .then(() => {
          dispatch(setUser("anonymous"));
        })
        .catch(console.error)
        .finally(() => {
          openIndexedDB().finally(() => {
            setLoading(false);
          });
        });
    }
  }, [dispatch, user]);

  return { loading };
};

export default useAuth;
