import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./views/Loading";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import routes from "./constants/routes";
import useAuth from "./hooks/useAuth";

const AppRouter = () => {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={routes.HOME.path} element={<Home />} />
        <Route path={routes.NOT_FOUND.path} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
