import { SET_SEARCH_TEXT, SET_SYNC } from "./types";

const initialState = {
  searchText: "",
  sync: false,
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TEXT: {
      return { ...state, searchText: action.payload };
    }

    case SET_SYNC: {
      return { ...state, sync: action.payload };
    }

    default:
      return state;
  }
};

export default stateReducer;
