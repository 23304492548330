import dayjs from "dayjs";

import { UPDATE_LAST_SYNC } from "./types";

const initialState = {
  lastSync: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LAST_SYNC: {
      return { ...state, lastSync: dayjs().toISOString() };
    }

    default:
      return state;
  }
};

export default appReducer;
