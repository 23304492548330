import {
  countItems,
  clearItems,
  addItems,
  replaceItems,
  searchItemsByWords,
  searchItemsAcrossTables,
  searchItems,
  getItems,
} from "./index";

export const countProductos = () => countItems("productos");

export const clearProductos = () => clearItems("productos");

export const addProductos = (items) => addItems(items, "productos");

export const replaceProductos = (items) => replaceItems(items, "productos");

export const searchProductosBySku = (sku) =>
  searchItems(sku.trim(), "pr_sku", "productos");

export const searchProductosByWords = (words) => {
  if (words.trim()) {
    return searchItemsByWords(words.trim(), "id", "productos");
  } else {
    return getItems("productos");
  }
};

export const searchProductosByCode = (code) =>
  searchItemsAcrossTables({
    searchText: code.trim(),
    searchId: "gtin_code",
    searchTable: "codigosDeBarra",
    originId: "gtin_sku",
    targetId: "pr_sku",
    targetTable: "productos",
  });

export const searchProductos = (text) =>
  new Promise((resolve, reject) => {
    if (/^\d+$/.test(text.trim())) {
      searchProductosByCode(text.trim())
        .then((data) => {
          if (data.length) {
            resolve(data);
          } else {
            searchProductosByWords(text.trim()).then(resolve).catch(reject);
          }
        })
        .catch(reject);
    } else if (/^\d{2}-\d{3}-\d{3}-\d{3}$/.test(text.trim())) {
      searchProductosBySku(text.trim()).then(resolve).catch(reject);
    } else {
      searchProductosByWords(text.trim()).then(resolve).catch(reject);
    }
  });
