import { useDispatch } from "react-redux";
import { Input } from "antd";

import { setSearchText } from "../redux/state/actions";

const Searchbar = () => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setSearchText(event.target.value));
  };

  return <Input.Search placeholder="Buscar producto" onChange={handleChange} />;
};

export default Searchbar;
