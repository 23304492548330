import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import dayjs from "dayjs";

import supabase from "../helpers/supabase";
import { replaceProductos } from "../indexedDB/productos";
import { updateLastSync } from "../redux/app/actions";
import { setSync } from "../redux/state/actions";
import { lastSyncSelector } from "../redux/app/selectors";
import { syncSelector } from "../redux/state/selectors";

const Statusbar = () => {
  const dispatch = useDispatch();
  const sync = useSelector(syncSelector);
  const lastSync = useSelector(lastSyncSelector);
  const [isUpdated, setIsUpdated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleFetch = async () => {
    try {
      dispatch(setSync(true));
      const { data: dataFile, error } = await supabase.storage
        .from("datasource")
        .download("public/productos.json");
      if (!error) {
        const arrayBuffer = await dataFile.arrayBuffer();
        const jsonString = new TextDecoder("utf-8").decode(arrayBuffer);
        const jsonObject = JSON.parse(jsonString);
        await replaceProductos(jsonObject);
        dispatch(updateLastSync());
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setSync(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dataList } = await supabase.storage
          .from("datasource")
          .list("public");
        const element = dataList.filter(
          (item) => item.name === "productos.json"
        )[0];
        if (lastSync && element) {
          if (dayjs(element.updated_at).isAfter(dayjs(lastSync))) {
            setIsUpdated(false);
          } else {
            setIsUpdated(true);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, lastSync]);

  if (sync) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tag icon={<SyncOutlined spin />} color="processing">
          Sincronizando...
        </Tag>
      </div>
    );
  } else if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tag icon={<SyncOutlined spin />} color="default">
          Buscando actualizaciones...
        </Tag>
      </div>
    );
  } else if (isUpdated) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tag icon={<CheckCircleOutlined />} color="success">
          Actualizado
        </Tag>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tag
          icon={<InfoCircleOutlined />}
          color="warning"
          onClick={handleFetch}
          style={{ cursor: "pointer" }}
        >
          Hay una actualización
        </Tag>
      </div>
    );
  }
};

export default Statusbar;
