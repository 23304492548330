import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";

import useSearchProductos from "../hooks/useSearchProductos";
import { syncSelector } from "../redux/state/selectors";

const DataTable = () => {
  const sync = useSelector(syncSelector);
  const { productos, isSearching } = useSearchProductos();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [productos]);

  return (
    <Table
      locale={{ emptyText: "No hay coincidencias" }}
      scroll={{ x: true }}
      pagination={{
        current: currentPage,
        pageSize: 10,
        showSizeChanger: false,
      }}
      showSorterTooltip={false}
      loading={sync || isSearching}
      rowKey="id"
      dataSource={productos}
      onChange={(p) => setCurrentPage(p.current)}
      columns={[
        {
          title: "Clave",
          dataIndex: "cve_prod",
          key: "cve_prod",
          sorter: (a, b) => a.cve_prod.localeCompare(b.cve_prod),
        },
        {
          title: "Descripción",
          dataIndex: "desc_prod",
          key: "desc_prod",
          sorter: (a, b) => a.desc_prod.localeCompare(b.desc_prod),
        },
        {
          title: "Unidad",
          dataIndex: "uni_med",
          key: "uni_med",
          sorter: (a, b) => a.uni_med.localeCompare(b.uni_med),
        },
        {
          title: "Precio",
          dataIndex: "prec_prod_2",
          key: "prec_prod_2",
          sorter: (a, b) =>
            parseFloat(a.prec_prod_2) - parseFloat(b.prec_prod_2),
        },
        {
          title: "sub_cse",
          dataIndex: "sub_cse",
          key: "sub_cse",
          sorter: (a, b) => a.sub_cse.localeCompare(b.sub_cse),
        },
        {
          title: "sub_subcse",
          dataIndex: "sub_subcse",
          key: "sub_subcse",
          sorter: (a, b) => a.sub_subcse.localeCompare(b.sub_subcse),
        },
        {
          title: "des_tial",
          dataIndex: "des_tial",
          key: "des_tial",
          sorter: (a, b) => a.des_tial.localeCompare(b.des_tial),
        },
        {
          title: "Existencia",
          dataIndex: "existencia",
          key: "existencia",
          sorter: (a, b) => parseFloat(a.existencia) - parseFloat(b.existencia),
          filters: [{ text: "En existencia", value: "exists" }],
          onFilter: (value, record) => record.existencia > 0,
        },
      ]}
    />
  );
};

export default DataTable;
