import Dexie from "dexie";

function getAllWords(text) {
  let allWordsIncludingDups = text
    .toLowerCase()
    .replace(/[,()]/g, " ")
    .replace(/[^.a-zñáéíóúü0-9\s/]/gi, "")
    .split(" ");
  let wordSet = allWordsIncludingDups.reduce(function (prev, current) {
    if (current !== "") {
      prev[current] = true;
    }
    return prev;
  }, {});
  return Object.keys(wordSet);
}

const db = new Dexie("valmexDB");
db.version(1).stores({
  productos:
    "id, cve_prod, desc_prod, uni_med, prec_prod_2, sub_cse, sub_subcse, des_tial, existencia, *words",
});

db.productos.hook("creating", function (primKey, obj, trans) {
  if (typeof obj.cve_prod == "string") {
    obj.words = getAllWords(obj.cve_prod);
  }
  if (typeof obj.desc_prod == "string") {
    obj.words = obj.words.concat(getAllWords(obj.desc_prod));
  }
  if (typeof obj.uni_med == "string") {
    obj.words = obj.words.concat(getAllWords(obj.uni_med));
  }
  if (typeof obj.sub_cse == "string") {
    obj.words = obj.words.concat(getAllWords(obj.sub_cse));
  }
  if (typeof obj.sub_subcse == "string") {
    obj.words = obj.words.concat(getAllWords(obj.sub_subcse));
  }
  if (typeof obj.des_tial == "string") {
    obj.words = obj.words.concat(getAllWords(obj.des_tial));
  }
});

db.productos.hook("updating", function (mods, primKey, obj, trans) {
  let words = [];
  if (mods.hasOwnProperty("cve_prod")) {
    if (typeof mods.cve_prod == "string") {
      words = words.concat(getAllWords(mods.cve_prod));
    }
  }
  if (mods.hasOwnProperty("desc_prod")) {
    if (typeof mods.desc_prod == "string") {
      words = words.concat(getAllWords(mods.desc_prod));
    }
  }
  if (mods.hasOwnProperty("uni_med")) {
    if (typeof mods.uni_med == "string") {
      words = words.concat(getAllWords(mods.uni_med));
    }
  }
  if (mods.hasOwnProperty("sub_cse")) {
    if (typeof mods.sub_cse == "string") {
      words = words.concat(getAllWords(mods.sub_cse));
    }
  }
  if (mods.hasOwnProperty("sub_subcse")) {
    if (typeof mods.sub_subcse == "string") {
      words = words.concat(getAllWords(mods.sub_subcse));
    }
  }
  if (mods.hasOwnProperty("des_tial")) {
    if (typeof mods.des_tial == "string") {
      words = words.concat(getAllWords(mods.des_tial));
    }
  }
  return { words: words };
});

export const countItems = (table) => {
  return db[table].count();
};

export const clearItems = (table) => {
  return db[table].clear();
};

export const getItem = (id, table) => {
  return new Promise((resolve, reject) => {
    db[table].get(id).then(resolve).catch(reject);
  });
};

export const getItems = (table) => {
  return new Promise((resolve, reject) => {
    db[table].toArray().then(resolve).catch(reject);
  });
};

export const addItem = (item, table) => {
  return new Promise((resolve, reject) => {
    db[table].add(item).then(resolve).catch(reject);
  });
};

export const addItems = (items, table) => {
  return new Promise((resolve, reject) => {
    db[table].bulkAdd(items).then(resolve).catch(reject);
  });
};

export const replaceItems = (items, table) => {
  return new Promise((resolve, reject) => {
    if (countItems(table)) {
      clearItems(table)
        .then(() => {
          addItems(items, table).then(resolve).catch(reject);
        })
        .catch(reject);
    } else {
      addItems(items, table).then(resolve).catch(reject);
    }
  });
};

export const searchItems = (searchText, id, table) => {
  return new Promise((resolve, reject) => {
    if (searchText) {
      return db[table]
        .where(id)
        .startsWithIgnoreCase(searchText)
        .toArray()
        .then(resolve)
        .catch(reject);
    } else {
      resolve([]);
    }
  });
};

export const searchItemsByWords = (searchText, id, table) => {
  return new Promise((resolve, reject) => {
    if (searchText) {
      const words = searchText
        .toLowerCase()
        .replace(/[-,()]/g, " ")
        .split(" ")
        .filter(Boolean);
      if (words.length) {
        const promises = words.map((word) => {
          return db[table].where("words").startsWith(word).primaryKeys();
        });

        Promise.all(promises)
          .then((results) => {
            const reduced = results.reduce((prev, current) => {
              const set = new Set(current);
              return prev.filter((item) => set.has(item));
            });
            return db[table].where(id).anyOf(reduced).toArray();
          })
          .then(resolve)
          .catch(reject);
      } else {
        resolve([]);
      }
    } else {
      resolve([]);
    }
  });
};

export const searchItemsAcrossTables = ({
  searchText,
  searchId,
  searchTable,
  originId,
  targetId,
  targetTable,
}) => {
  return new Promise((resolve, reject) => {
    if (searchText) {
      return db[searchTable]
        .where(searchId)
        .startsWith(searchText)
        .toArray()
        .then((results) => {
          if (results.length) {
            const ids = results.map((item) => item[originId]);
            return db[targetTable]
              .where(targetId)
              .anyOf(ids)
              .toArray()
              .then(resolve)
              .catch(reject);
          } else {
            resolve([]);
          }
        })
        .then(resolve)
        .catch(reject);
    } else {
      resolve([]);
    }
  });
};

export const creatingItems = (listener, table) => {
  return db[table].hook("creating", listener);
};

export const unsubscribeCreatingItems = (listener, table) => {
  return db[table].hook("creating").unsubscribe(listener);
};

export const updatingItems = (listener, table) => {
  return db[table].hook("updating", listener);
};

export const unsubscribeUpdatingItems = (listener, table) => {
  return db[table].hook("updating").unsubscribe(listener);
};

export const deletingItems = (listener, table) => {
  return db[table].hook("deleting", listener);
};

export const unsubscribeDeletingItems = (listener, table) => {
  return db[table].hook("deleting").unsubscribe(listener);
};

export const openIndexedDB = () => {
  return new Promise((resolve, reject) => {
    db.open().then(resolve).catch(reject);
  });
};

export const deleteIndexedDB = () => {
  return new Promise((resolve, reject) => {
    db.delete().then(resolve).catch(reject);
  });
};

export default db;
