import { useEffect } from "react";
import { useDispatch } from "react-redux";

import supabase from "../helpers/supabase";
import { countProductos, replaceProductos } from "../indexedDB/productos";
import { updateLastSync } from "../redux/app/actions";
import { setSync } from "../redux/state/actions";

const useFetchProductos = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const counting = await countProductos();
        if (!counting) {
          dispatch(setSync(true));
          const { data, error } = await supabase.storage
            .from("datasource")
            .download("public/productos.json");
          if (!error) {
            const arrayBuffer = await data.arrayBuffer();
            const jsonString = new TextDecoder("utf-8").decode(arrayBuffer);
            const jsonObject = JSON.parse(jsonString);
            await replaceProductos(jsonObject);
            dispatch(updateLastSync());
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setSync(false));
      }
    };

    fetchData();
  }, [dispatch]);
};

export default useFetchProductos;
