import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { searchProductosByWords } from "../indexedDB/productos";
import { searchTextSelector, syncSelector } from "../redux/state/selectors";

const useSearchProductos = () => {
  const searchText = useSelector(searchTextSelector);
  const sync = useSelector(syncSelector);
  const [productos, setProductos] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!sync) {
      let didCancel = false;
      setIsSearching(true);
      searchProductosByWords(searchText)
        .then((data) => {
          if (didCancel) return;
          setProductos(data);
          setIsSearching(false);
        })
        .catch(() => {
          if (didCancel) return;
          setIsSearching(false);
        });
      return () => {
        didCancel = true;
      };
    }
  }, [searchText, sync]);

  return { productos, isSearching };
};

export default useSearchProductos;
