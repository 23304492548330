import { SET_SEARCH_TEXT, SET_SYNC } from "./types";

export const setSearchText = (searchText) => {
  return {
    type: SET_SEARCH_TEXT,
    payload: searchText,
  };
};

export const setSync = (sync) => {
  return {
    type: SET_SYNC,
    payload: sync,
  };
};
